import { FC, ReactNode } from 'react';
import Modal from '..';
import { Button } from 'components/clickers/button/button';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  isVisible: boolean;
  children: ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmationModal: FC<Props> = ({
  isVisible,
  children,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isVisible={isVisible} onClose={onCancel}>
      <div className="confirmation-modal-message" style={{ margin: '20px' }}>
        {children}
      </div>
      <br />
      <Grid style={{ marginTop: '20px' }}>
        <Button
          style={{ width: '100px', marginLeft: '20px' }}
          onClick={onConfirm}
        >
          {t('Yes')}
        </Button>
        <Button
          style={{ width: '100px', marginLeft: '10px' }}
          onClick={onCancel}
        >
          {t('No')}
        </Button>
      </Grid>
    </Modal>
  );
};

export default ConfirmationModal;

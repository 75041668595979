import { ApiLink } from '_api/Links/LinksApi';

export const getApiHrefByRel = (
  links: ApiLink[],
  apiRel: string,
): string | undefined => {
  const link = links && links.find((item) => item.Rel === apiRel && item.Href);
  return link ? link.Href : undefined;
};

export const ApiLinksHelper = {
  getApiHrefByRel,
};

import useLinksQuery from '_api/Links';
import { AxiosResponse } from 'axios';
import { axiosGet } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import {
  ParkingSessionsApiResponse,
  UnCompletedPaymentApiResponse,
} from 'interfaces';
import { useEffect, useState } from 'react';
import { parseTemplate } from 'url-template';
type Return = {
  getParkingSessionInformation: (
    licensePlate: string,
    operatorGuid: string | null,
    carparkGuid: string | null,
  ) => Promise<AxiosResponse<ParkingSessionsApiResponse> | undefined>;
  getUncompletedPayments: (
    licensePlate: string,
  ) => Promise<AxiosResponse<UnCompletedPaymentApiResponse> | undefined>;
};
const useParkingSessions = (): Return => {
  const { data: endpoints } = useLinksQuery();
  const [parkingSessionLink, setParkingSessionLink] = useState<
    undefined | string
  >();
  const [uncompletedPaymentsLink, setUncompletedPaymentsLink] = useState<
    undefined | string
  >();

  useEffect(() => {
    if (endpoints) {
      setParkingSessionLink(getApiHrefByRel(endpoints, 'parking-sessions'));
      setUncompletedPaymentsLink(
        getApiHrefByRel(endpoints, 'uncompleted-parking-payments'),
      );
    }
  }, [endpoints]);

  const getParkingSessionInformation = async (
    licensePlate: string,
    operatorGuid: string | null,
    carparkGuid: string | null,
  ): Promise<AxiosResponse<ParkingSessionsApiResponse> | undefined> => {
    if (parkingSessionLink) {
      const urlTemplate = parseTemplate(decodeURI(parkingSessionLink));
      const apiLink = urlTemplate.expand({
        regno: licensePlate,
        carparkguid: carparkGuid,
        operatorguid: operatorGuid,
      });
      return await axiosGet(apiLink);
    }
  };

  const getUncompletedPayments = async (
    licensePlate: string,
  ): Promise<AxiosResponse<UnCompletedPaymentApiResponse> | undefined> => {
    if (uncompletedPaymentsLink) {
      const urlTemplate = parseTemplate(decodeURI(uncompletedPaymentsLink));
      const apiLink = urlTemplate.expand({
        regno: licensePlate,
      });
      return await axiosGet(apiLink);
    }
  };

  return {
    getParkingSessionInformation,
    getUncompletedPayments,
  };
};

export default useParkingSessions;

/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const checkHTTPstatus = (status: number): boolean => {
  if (status > 199 && status <= 299) {
    return true;
  } else if (status === 401) {
    window.location.href = '/login'; // Redirect to login page
    return false;
  } else {
    throw new Error('HTTP status error');
  }
};

const defaultAxiosConfig = {
  withCredentials: true,
};

const axiosGet = async <ReturnType>(
  endpoint: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ReturnType> | undefined> => {
  try {
    const response = await Axios.get(endpoint, {
      ...defaultAxiosConfig,
      ...config,
    });
    return checkHTTPstatus(response.status) ? response : undefined;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      // window.location.href = '/login'; // Redirect to login page
    }
    throw new Error(error);
  }
};

const axiosPut = async (
  endpoint: string,
  payload: object,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await Axios.put(endpoint, payload, {
      ...defaultAxiosConfig,
      ...config,
    });
    return checkHTTPstatus(response.status) && response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      // window.location.href = '/login'; // Redirect to login page
    }
    throw new Error(error);
  }
};

const axiosPost = async <ReturnType>(
  url: string,
  data: object,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ReturnType> | undefined> => {
  try {
    const response = await Axios.post(url, data, {
      ...defaultAxiosConfig,
      ...config,
    });
    return checkHTTPstatus(response.status) ? response : undefined;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      // window.location.href = '/login'; // Redirect to login page
    }
    throw error.response;
  }
};

const axiosDelete = async <ReturnType>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ReturnType> | undefined> => {
  try {
    const response = await Axios.delete(url, {
      ...defaultAxiosConfig,
      ...config,
    });
    return checkHTTPstatus(response.status) ? response : undefined;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      // window.location.href = '/login'; // Redirect to login page
    }
    throw error.response;
  }
};

export { axiosGet, axiosPost, axiosPut, axiosDelete };

import { FC } from 'react';

import { Grid } from '@mui/material';

import Modal from 'components/modal';

import { StatusType } from 'pages/request-reset-password/RequestPasswordResetPage';
import AddVehicleForm from 'pages/vehicle/list/add/form';

type Props = {
  link: string;
  isOpened: boolean;
  handleClose: () => void;
  callback: () => void;
  status: (status: StatusType) => void;
};
const AddVehicleModal: FC<Props> = ({
  link,
  isOpened,
  handleClose,
  callback,
  status,
}) => {
  return (
    <Modal isVisible={isOpened} onClose={handleClose}>
      <Grid
        justifyContent={'center'}
        alignItems={'center'}
        display={'flex'}
        container
      >
        <Grid item xs={7} sx={{ textAlign: 'end', paddingBottom: 1 }}></Grid>
        <Grid item sm={6}>
          <AddVehicleForm
            link={link}
            status={status}
            onClose={handleClose}
            callback={callback}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddVehicleModal;

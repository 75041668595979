import { FC, useEffect, useRef, useState } from 'react';

import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { NO, SE, US } from 'country-flag-icons/react/3x2';

import { useSearchParams } from 'react-router-dom';
import './_languageSwitcher.scss';
import { useTranslation } from 'react-i18next';

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`,
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4.5)};
  height: ${theme.spacing(4.5)};
  border-radius: 12px;  
  margin-bottom:10px !important;
`,
);

type LanguageType = {
  lng: string;
};

type FlagProps = {
  type: 'en' | 'no' | 'se' | string;
};
const Flag: FC<FlagProps> = ({ type }) => {
  const flag = () => {
    switch (type) {
      case 'no':
        return <NO />;

      case 'se':
        return <SE />;

      default:
        return <US />;
    }
  };
  return <div style={{ width: '30px' }}>{flag()}</div>;
};

const LanguageSwitcher = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const storedLanguage = window.localStorage.getItem('Language');
  const langFromUrl = searchParams.get('lang');
  const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
  const theme = useTheme();

  useEffect(() => {
    if (langFromUrl) {
      setSelectedLanguage(langFromUrl);
      window.localStorage.setItem('Language', langFromUrl);
      i18n.changeLanguage(langFromUrl);
    }
  }, [i18n, langFromUrl]);

  const switchLanguage = ({ lng }: LanguageType) => {
    setSelectedLanguage(lng);
    i18n.changeLanguage(lng);
    window.localStorage.setItem('Language', lng);
  };

  const ref = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Box
      style={{
        maxWidth: '100px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '10px',
      }}
    >
      <Tooltip arrow title={t('Language switcher')}>
        <IconButtonWrapper
          color="secondary"
          ref={ref}
          onClick={handleOpen}
          style={{ margin: 'auto' }}
          sx={{
            background: 'rgba(255, 25, 67, 0.2)',
            transition: `${theme.transitions.create(['background'])}`,
            color: 'rgb(255, 25, 67)',
            '&:hover': {
              background: 'rgba(255, 25, 67, 0.2)',
            },
          }}
        >
          <Flag type={selectedLanguage ?? ''} />
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className="lang-popover"
      >
        <Box
          sx={{
            maxWidth: 240,
          }}
        >
          <SectionHeading variant="body2" className="language-switcher-heading">
            {t('Language switcher')}
          </SectionHeading>
          <List
            sx={{
              p: 2,
            }}
            component="nav"
          >
            <ListItem
              className={
                selectedLanguage === 'en' || selectedLanguage === 'en-US'
                  ? 'active'
                  : ''
              }
              button
              onClick={() => {
                switchLanguage({ lng: 'en' });
                handleClose();
              }}
            >
              <Flag type="en" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary={t('English')}
              />
            </ListItem>

            <ListItem
              className={selectedLanguage === 'no' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'no' });
                handleClose();
              }}
            >
              <Flag type="no" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary={t('Norwegian')}
              />
            </ListItem>

            <ListItem
              className={selectedLanguage === 'se' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'se' });
                handleClose();
              }}
            >
              <Flag type="se" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary={t('Swedish')}
              />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </Box>
  );
};

export default LanguageSwitcher;

import { FC, useMemo } from 'react';
import { getTimeDifference } from 'helpers/get-time-difference';
import { IParkingSession } from 'interfaces';

import './parking-session-row.scss';
import ParkingSessionDetail from './detail';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

type Props = {
  data: IParkingSession;
  timezone: string;
};

const ParkingSessionRow: FC<Props> = ({ data, timezone }) => {
  const { EnteredAt, ExitedAt, ParkingFee } = data;
  const { t } = useTranslation();
  const duration = useMemo(
    () => getTimeDifference(EnteredAt, ExitedAt),
    [EnteredAt, ExitedAt],
  );

  const started = useMemo(() => {
    const timeZonned = moment(EnteredAt).tz(timezone);

    return (
      timeZonned.format('DD.MM.YYYY') + t(' at ') + timeZonned.format('HH:mm')
    );
  }, [EnteredAt, t, timezone]);

  return (
    <div className="parking-session-row">
      <ParkingSessionDetail heading="Started" content={started} />

      <ParkingSessionDetail heading="Duration" content={duration} />

      <ParkingSessionDetail
        heading={'Price'}
        content={ParkingFee.TotalAmount}
      />
    </div>
  );
};

export default ParkingSessionRow;

import {
  IconR,
  LinkButtonTo,
  Page,
  Button,
  Form,
  FormGroup,
  InputText,
  Loader,
} from 'components';
import { useTranslation } from 'react-i18next';
import { CiFaceSmile } from 'react-icons/ci';
import { EmailRegex } from 'static';
import './_payment-complete-page.scss';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { ILastPaymentSession, IParkingFee, IPaymentDetail } from 'interfaces';
import { axiosPost } from 'helpers';
import { TranslatedString } from 'i18n/translations/no';
import parse from 'html-react-parser';
import Notification from 'components/notification';
import { Routes } from 'pages';

interface IForm {
  email: string;
}

const PaymentCompletePage = (): JSX.Element => {
  const { t } = useTranslation();
  const baseClassName = 'payment-complete-page';
  const methods = useForm<IForm>({ defaultValues: { email: '' } });
  const { control, handleSubmit } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState<TranslatedString>();
  const [showSuccessMessage, setShowSuccessMessage] =
    useState<TranslatedString>();
  const lastPaymentSessionData: ILastPaymentSession = JSON.parse(
    window.localStorage.getItem('last_payment_session_data') ?? '',
  );

  const onSubmit = async (data: IForm) => {
    const email = data.email;

    if (!EmailRegex.test(email)) {
      setShowErrorMessage('Please enter valid email address');
      return;
    }

    if (lastPaymentSessionData) {
      setIsLoading(true);
      setShowSuccessMessage(undefined);
      setShowErrorMessage(undefined);
      axiosPost(lastPaymentSessionData.RequestReceiptLink, {
        Email: data.email,
      })
        .then((response) => {
          console.log('response?.data : ', response?.data);
          setShowSuccessMessage(
            'Your receipt will be sent to your inbox shortly',
          );
        })
        .catch((error) => {
          console.error(error);
          setShowErrorMessage('Something went wrong with sending the receipt');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const paymentPaidMessage = lastPaymentSessionData.OperatorName
    ? 'Successfully paid <b>{{total}}</b> to <b>{{operatorName}}</b>'
    : 'Successfully paid <b>{{total}}</b>';

  let total =
    (lastPaymentSessionData.TotalParkingFee as IParkingFee).TotalAmount ??
    (lastPaymentSessionData.TotalParkingFee as IPaymentDetail).Amount;
  const { Currency } =
    (lastPaymentSessionData.TotalParkingFee as IPaymentDetail) ?? {};
  if (Currency) {
    total += ' ' + Currency;
  }
  return (
    <Page className={baseClassName}>
      <span className={`${baseClassName}__heading-message`}>
        {t('Payment complete')}
      </span>
      <div className={`${baseClassName}__icon`}>
        <IconR icon={CiFaceSmile} />
      </div>

      <span className={`${baseClassName}__payment-message`}>
        {parse(
          t(paymentPaidMessage, {
            total: total,
            operatorName: lastPaymentSessionData.OperatorName,
          }),
        )}
      </span>

      <span className={`${baseClassName}__payment-message`}>
        {t(
          'Parking is now paid and we want you to be safe until your next destination.',
        )}
      </span>

      <LinkButtonTo style={{ marginTop: 10 }} to={Routes.Landing}>
        {t('Home')}
      </LinkButtonTo>

      {!showEmailForm && (
        <Button
          onClick={() => setShowEmailForm(true)}
          style={{ marginTop: 10 }}
        >
          {t('Request receipt')}
        </Button>
      )}

      {showEmailForm && (
        <div style={{ marginTop: 20 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup formatClassName="dropdown-combo">
              <InputText
                name="email"
                label={t('Email address')}
                control={control}
                required
                maxLength={254}
              />
            </FormGroup>
            <Button type="submit">
              <Loader loading={isLoading}>{t('Send receipt')}</Loader>
            </Button>
          </Form>
        </div>
      )}

      {showErrorMessage && (
        <Notification severity="error">{t(showErrorMessage)}</Notification>
      )}

      {showSuccessMessage && (
        <Notification severity="success">{t(showSuccessMessage)}</Notification>
      )}
    </Page>
  );
};

export default PaymentCompletePage;
